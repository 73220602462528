<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8836_79740)">
      <path
        d="M22.7373 8.34424C23.0339 8.34424 23.2744 8.10815 23.2744 7.81689V2.08203C23.2744 1.2097 22.5516 0.5 21.6631 0.5H2.1123C1.22382 0.5 0.500977 1.2097 0.500977 2.08203V25.918C0.500977 26.7903 1.22382 27.5 2.1123 27.5H21.6631C22.5516 27.5 23.2744 26.7903 23.2744 25.918V22.0329C23.2744 21.7417 23.0339 21.5056 22.7373 21.5056C22.4407 21.5056 22.2002 21.7417 22.2002 22.0329V25.918C22.2002 26.2087 21.9592 26.4453 21.6631 26.4453H2.1123C1.81614 26.4453 1.5752 26.2087 1.5752 25.918V2.08203C1.5752 1.79125 1.81614 1.55469 2.1123 1.55469H21.6631C21.9592 1.55469 22.2002 1.79125 22.2002 2.08203V7.81689C22.2002 8.10815 22.4407 8.34424 22.7373 8.34424Z"
        :fill="`${color}`"
      />
      <path
        d="M11.8872 3.34766C9.1625 3.34766 6.9458 5.52406 6.9458 8.19922C6.9458 9.58703 7.54253 10.8404 8.49708 11.7255C8.51497 11.7443 8.53398 11.7621 8.55477 11.7782C9.43407 12.5682 10.6043 13.0507 11.8872 13.0507C13.17 13.0507 14.3402 12.5681 15.2195 11.7782C15.2403 11.7621 15.2593 11.7442 15.2772 11.7255C16.2319 10.8404 16.8286 9.58703 16.8286 8.19922C16.8286 5.52406 14.6119 3.34766 11.8872 3.34766ZM11.8872 11.9961C11.0102 11.9961 10.2007 11.7075 9.55126 11.2226C10.0233 10.4007 10.9042 9.88672 11.8872 9.88672C12.8702 9.88672 13.7511 10.4007 14.2231 11.2226C13.5736 11.7075 12.7643 11.9961 11.8872 11.9961ZM11.0278 7.98828V7.64551C11.0278 7.18029 11.4134 6.80176 11.8872 6.80176C12.361 6.80176 12.7466 7.18029 12.7466 7.64551V7.98828C12.7466 8.4535 12.361 8.83203 11.8872 8.83203C11.4134 8.83203 11.0278 8.4535 11.0278 7.98828ZM14.9992 10.4501C14.6031 9.87327 14.0541 9.42466 13.4167 9.1477C13.6697 8.8267 13.8208 8.42461 13.8208 7.98828V7.64551C13.8208 6.59868 12.9534 5.74707 11.8872 5.74707C10.821 5.74707 9.95361 6.59868 9.95361 7.64551V7.98828C9.95361 8.42461 10.1047 8.8267 10.3577 9.1477C9.72029 9.42466 9.17131 9.87327 8.7752 10.4501C8.30098 9.81975 8.02002 9.04123 8.02002 8.19922C8.02002 6.10561 9.75483 4.40234 11.8872 4.40234C14.0196 4.40234 15.7544 6.10561 15.7544 8.19922C15.7544 9.04123 15.4734 9.81975 14.9992 10.4501Z"
        :fill="`${color}`"
      />
      <path
        d="M14.7876 23.5977H11.8872C11.5906 23.5977 11.3501 23.8337 11.3501 24.125C11.3501 24.4163 11.5906 24.6523 11.8872 24.6523H14.7876C15.0842 24.6523 15.3247 24.4163 15.3247 24.125C15.3247 23.8337 15.0842 23.5977 14.7876 23.5977Z"
        :fill="`${color}`"
      />
      <path
        d="M14.7876 20.6445H7.16064C6.86405 20.6445 6.62354 20.8806 6.62354 21.1719C6.62354 21.4631 6.86405 21.6992 7.16064 21.6992H14.7876C15.0842 21.6992 15.3247 21.4631 15.3247 21.1719C15.3247 20.8806 15.0842 20.6445 14.7876 20.6445Z"
        :fill="`${color}`"
      />
      <path
        d="M14.7876 17.6914H7.16064C6.86405 17.6914 6.62354 17.9275 6.62354 18.2188C6.62354 18.51 6.86405 18.7461 7.16064 18.7461H14.7876C15.0842 18.7461 15.3247 18.51 15.3247 18.2188C15.3247 17.9275 15.0842 17.6914 14.7876 17.6914Z"
        :fill="`${color}`"
      />
      <path
        d="M5.2457 14.8928C5.14585 14.7947 5.00728 14.7383 4.86548 14.7383C4.72416 14.7383 4.58559 14.7947 4.48574 14.8928C4.38584 14.9909 4.32837 15.1269 4.32837 15.2656C4.32837 15.4043 4.38579 15.5404 4.48574 15.6385C4.58618 15.7365 4.72416 15.793 4.86548 15.793C5.00728 15.793 5.14531 15.7365 5.2457 15.6385C5.3456 15.5404 5.40312 15.4043 5.40312 15.2656C5.40312 15.1269 5.34565 14.9909 5.2457 14.8928Z"
        :fill="`${color}`"
      />
      <path
        d="M5.2457 17.8459C5.14531 17.7478 5.00728 17.6914 4.86548 17.6914C4.72416 17.6914 4.58618 17.7478 4.48574 17.8459C4.38584 17.944 4.32837 18.0801 4.32837 18.2188C4.32837 18.3574 4.38579 18.4935 4.48574 18.5916C4.58618 18.6897 4.72416 18.7461 4.86548 18.7461C5.00728 18.7461 5.14531 18.6897 5.2457 18.5916C5.3456 18.4935 5.40312 18.3574 5.40312 18.2188C5.40312 18.0801 5.34565 17.944 5.2457 17.8459Z"
        :fill="`${color}`"
      />
      <path
        d="M5.2457 20.799C5.14531 20.701 5.00728 20.6445 4.86548 20.6445C4.72416 20.6445 4.58618 20.701 4.48574 20.799C4.38584 20.8971 4.32837 21.0332 4.32837 21.1719C4.32837 21.3106 4.38579 21.4466 4.48574 21.5447C4.58559 21.6428 4.72416 21.6992 4.86548 21.6992C5.00728 21.6992 5.14585 21.6428 5.2457 21.5447C5.3456 21.4466 5.40312 21.3106 5.40312 21.1719C5.40312 21.0332 5.34565 20.8971 5.2457 20.799Z"
        :fill="`${color}`"
      />
      <path
        d="M14.7876 14.7383H7.16064C6.86405 14.7383 6.62354 14.9744 6.62354 15.2656C6.62354 15.5569 6.86405 15.793 7.16064 15.793H14.7876C15.0842 15.793 15.3247 15.5569 15.3247 15.2656C15.3247 14.9744 15.0842 14.7383 14.7876 14.7383Z"
        :fill="`${color}`"
      />
      <path
        d="M26.872 7.37238C26.3502 7.0766 25.7423 6.99802 25.1602 7.15111C24.5782 7.30425 24.0917 7.67075 23.7905 8.18307L17.0314 19.6772C16.9944 19.7402 16.9709 19.8101 16.9627 19.8824L16.487 24.0666C16.4633 24.2747 16.5674 24.477 16.7522 24.5818C16.8356 24.629 16.9283 24.6524 17.0207 24.6524C17.133 24.6524 17.245 24.6179 17.3392 24.5497L20.792 22.0531C20.8517 22.0099 20.9016 21.9551 20.9386 21.8921L27.6977 10.398C28.3196 9.34032 27.9493 7.98305 26.872 7.37238ZM17.6907 22.9857L17.9392 20.8008L19.4937 21.682L17.6907 22.9857ZM20.2769 20.908L18.2303 19.7479L23.6543 10.5241L25.7009 11.6843L20.2769 20.908ZM26.7674 9.87057L26.238 10.7709L24.1913 9.61075L24.7207 8.71041C24.8785 8.44205 25.1333 8.25009 25.4382 8.16988C25.7432 8.08962 26.0616 8.13086 26.3349 8.2858C26.6082 8.44073 26.8037 8.6909 26.8854 8.99022C26.9671 9.28954 26.9252 9.6022 26.7674 9.87057Z"
        :fill="`${color}`"
      />
    </g>
    <defs>
      <clipPath id="clip0_8836_79740">
        <rect width="27.5" height="27" :fill="`${color}`" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '28'
    },
    height: {
      type: String,
      default: '28'
    }
  }
}
</script>
